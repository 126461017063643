@import "@/assets/css/prepend.scss";
@import "./global.scss";
@import "./cropper.scss";
@import "./form.scss";
.sD {
  @include mob {
    display: none;
  }
}
.sM {
  display: none;
  @include mob {
    display: block;
  }
}
header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 98;
  padding-top: $d30px;
  height: $d55px;
  @include mob {
    height: $m30px;
    padding-top: $m15px;
  }
  .container {
    position: relative;
  }
  .raw {
    display: grid;
    grid-template-columns: $d400px 1fr;
    gap: 0;
    @include mob {
      grid-template-columns: $m200px 1fr;
    }
    .lCol {
      .logo {
        width: $d313px;
        height: $d25px;
        background-image: url(../images/logo.svg);
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        @include mob {
          width: $m184px;
          height: $m15px;
        }
      }
    }
    .rCol {
      @include mob {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .menu {
        @include mob {
          display: none;
        }
        height: $d25px;
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        align-items: center;
        justify-content: flex-end;
        li {
          display: block;
          & + li {
            margin-left: $d25px;
          }
          a {
            font-size: $d16px;
            line-height: $d18px;
            color: #16351e;
          }
        }
      }
      .open {
        width: $m20px;
        height: $m6px;
        background-image: url(../images/open.svg);
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        display: none;
        @include mob {
          display: block;
        }
      }
    }
  }
}
.shadow {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.2);
  display: none;
}
.mobileMenu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  background: #4bb064;
  padding-bottom: $m50px;
  padding-top: $m15px;
  display: none;

  .raw {
    display: grid;
    grid-template-columns: $m200px 1fr;
    gap: 0;
    .lCol {
      .logo {
        width: $m184px;
        height: $m15px;
        background-image: url(../images/logo.svg);
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    .rCol {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .close {
        width: $m16px;
        height: $m16px;
        background-image: url(../images/close.svg);
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        display: block;
      }
    }
  }
  .menuBody {
    padding-top: $m25px;
    padding-left: $m55px;
    .menu {
      display: block;
      margin: 0;
      padding: 0;
      li {
        display: block;
        & + li {
          margin-top: $m10px;
        }
        a {
          font-size: $m14px;
          line-height: $m16px;
          color: #16351e;
        }
      }
    }
  }
}
body.showMenu .mobileMenu {
  display: block;
}
body.showMenu .shadow {
  display: block;
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: $d1200px;
  @include mob {
    max-width: $m355px;
  }
}
a {
  cursor: pointer;
}
section.home {
  width: 100%;
  height: $d750px;
  background: #4bb064;
  position: relative;
  @include mob {
    height: $m660px;
    padding-top: $m80px;
  }
  .container {
    height: 100%;
    position: relative;
    padding-top: $d271px;
    @include mob {
      padding-top: 0;
      position: static;
      height: auto;
    }
  }
  .mainTitle {
    font-family: "Druk Text Wide Cyr";
    font-style: normal;
    font-weight: 900;
    font-size: $d64px;
    line-height: $d81px;
    color: #000000;
    @include mob {
      margin-top: $m22px;
      font-size: $m34px;
      line-height: $m43px;
    }
  }
  .mainDescription {
    font-size: $d20px;
    line-height: 135%;
    color: #1a1a1a;
    margin-top: $d28px;
    @include mob {
      font-size: $m18px;
      line-height: 135%;
    }
  }
  .mainItems {
    margin-top: $d75px;
    display: flex;
    flex-direction: row;
    @include mob {
      display: block;
    }
    .groupOfIcons {
      width: $d400px;
      height: $d100px;
      background-image: url(../images/home_icons.svg);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      @include mob {
        display: none;
      }
    }
    .mainButtonBox {
      margin-top: -$d7px;
      width: $d114px;
      height: $d114px;
      @include mob {
        position: absolute;
        right: $m10px;
        bottom: $m30px;
        width: $m76px;
        height: $m76px;
      }
      .mainButton {
        display: flex;
        width: $d114px;
        height: $d114px;
        background-image: url(../images/home_button.svg);
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .boll {
          width: $d70px;
          height: $d70px;
          background: #000000;
          border-radius: $d100px;
          @include transition(all 0.2s linear);
          @include mob {
            width: $m50px;
            height: $m50px;
          }
        }
        animation: rotateBtn 6s infinite linear;
        &:hover {
          .boll {
            width: $d20px;
            height: $d20px;
            @include mob {
              width: $m20px;
              height: $m20px;
            }
          }
        }
        @include mob {
          width: $m76px;
          height: $m76px;
        }
      }
    }
  }
  .mainInst {
    position: absolute;
    right: $d40px;
    top: $d118px;
    height: $d557px;
    width: $d590px;
    @include mob {
      position: relative;
      width: $m355px;
      height: $m385px;
      right: auto;
      top: auto;
      margin: 0 auto;
    }
    .f1,
    .f2,
    .f3,
    .f4,
    .f5,
    .f6 {
      @include transition(all 0.4s ease-out);
    }
    .f1 {
      position: absolute;
      top: 0;
      right: 0;
      width: $d290px;
      height: $d557px;
      background-image: url(../images/f/f1.png);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      @include transition(all 0.6s ease-out);
      @include mob {
        right: auto;
        left: 0;
        width: $m199px;
        height: $m383px;
        opacity: 0;
      }
    }
    .f2 {
      position: absolute;
      top: 0;
      right: $d150px;
      width: $d290px;
      height: $d557px;
      background-image: url(../images/f/f2.png);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      @include transition(all 0.2s ease-out);
      @include mob {
        right: auto;
        left: $m15px;
        width: $m199px;
        height: $m383px;
      }
    }
    .f3 {
      position: absolute;
      top: 0;
      right: $d240px;
      width: $d290px;
      height: $d557px;
      background-image: url(../images/f/f3.png);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      @include mob {
        right: auto;
        left: $m55px;
        width: $m199px;
        height: $m383px;
      }
    }
    .f4 {
      position: absolute;
      top: 0;
      right: $d300px;
      width: $d290px;
      height: $d557px;
      background-image: url(../images/f/f4.png);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      @include mob {
        right: auto;
        left: $m95px;
        width: $m199px;
        height: $m383px;
      }
    }
    .f5 {
      position: absolute;
      top: 0;
      right: $d80px;
      width: $d290px;
      height: $d557px;
      background-image: url(../images/f/f5.svg);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      opacity: 0;
      @include transition(all 0.2s ease-out);
      @include mob {
        right: auto;
        left: 0;
        width: $m199px;
        height: $m383px;
      }
    }
    .f6 {
      position: absolute;
      top: 0;
      right: $d80px;
      width: $d290px;
      height: $d557px;
      background-image: url(../images/f/f6.png);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      opacity: 0;
      @include mob {
        right: auto;
        left: 0;
        width: $m199px;
        height: $m383px;
      }
    }
    &.on {
      // .f1,.f2,.f3,.f4{
      //   right: $d80px;
      // }
    }
    &.fs1 {
      .f2 {
        right: $d80px;
        @include mob {
          right: auto;
          left: 0;
        }
      }
    }
    &.fs2 {
      .f2,
      .f3,
      .f1 {
        right: $d80px;
        @include mob {
          right: auto;
          left: 0;
          opacity: 1;
        }
      }
    }
    &.fs3 {
      .f2,
      .f3,
      .f1,
      .f4 {
        right: $d80px;
        @include mob {
          right: auto;
          left: 0;
        }
      }
    }
    &.s4 {
      .f5 {
        opacity: 1;
      }
    }
    &.s5 {
      .f6 {
        opacity: 1;
      }
    }
    &.s6 {
      .f5 {
        opacity: 0;
        right: 0;
        @include mob {
          right: auto;
          left: $m30px;
        }
      }
      .f6 {
        opacity: 0;
        right: 0;
        @include mob {
          right: auto;
          left: $m30px;
        }
      }
    }
    // @include mob{
    //   display: none;
    // }
  }
  // .mainInstMob{
  //   background-position: top left;
  //   background-size: 100%;
  //   background-repeat: no-repeat;
  //   background-image: url(../images/m_home_screens.png);
  //   width: $m255px;
  //   height: $m385px;
  //   display: none;
  //   @include mob{
  //     display: block;
  //   }
  // }
}

@keyframes rotateBtn {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.il {
  display: inline-block;
}
.about {
  width: 100%;
  background: #ffffff;
  position: relative;
  padding-top: $d100px;
  padding-bottom: $d100px;
  @include mob {
    padding-top: $m80px;
    padding-bottom: $m80px;
  }
  .container {
    position: relative;
  }
  .sTitle {
    color: #4bb064;
  }
  .recycle {
    position: absolute;
    right: 0;
    top: 0;
    width: $d130px;
    height: $d130px;
    background-image: url(../images/recycle.svg);
    background-position: top left;
    background-size: 100%;
    background-repeat: no-repeat;
    @include mob {
      display: none;
    }
  }
  .raw {
    margin-top: $d50px;
    display: grid;
    grid-template-columns: 1fr $d450px;
    gap: 0;
    @include mob {
      display: block;
      margin-top: $m15px;
    }
    .lCol {
      padding-left: $d100px;
      padding-right: $d150px;
      @include mob {
        padding-left: $m55px;
        padding-right: 0;
      }
      .p1 {
        margin-bottom: $d110px;
        font-size: $d20px;
        line-height: 135%;
        color: #1a1a1a;
        @include mob {
          margin-bottom: $m40px;
          font-size: $m17px;
          line-height: 135%;
        }
      }
      .p2 {
        font-family: "Druk Text Wide Cyr";
        font-style: normal;
        font-weight: 500;
        font-size: $d24px;
        line-height: 115%;
        color: #f18666;
        margin-bottom: $d50px;
        @include mob {
          display: none;
        }
      }
      .bigButtonBox {
        width: $d500px;
        height: $d100px;
        @include mob {
          display: none;
        }
        .bigButton {
          display: block;
          width: $d500px;
          height: $d100px;
          cursor: pointer;
          background-image: url(../images/s1_button.png);
          background-position: top left;
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .rCol {
      .indicators {
        .indicator {
          @include mob {
            padding-left: $m55px;
          }
          .title {
            font-family: "Druk Text Wide Cyr";
            font-style: normal;
            font-weight: 500;
            font-size: $d24px;
            line-height: 115%;
            color: #f18666;
            @include mob {
              font-size: $m17px;
            }
          }
          .description {
            margin-top: $d12px;
            font-size: $d16px;
            line-height: $d18px;
            color: #4d4d4d;
            @include mob {
              margin-top: $m10px;
              font-size: $m14px;
              line-height: $m16px;
            }
          }
          & + .indicator {
            margin-top: $d50px;
            @include mob {
              margin-top: $m20px;
            }
          }
        }
      }
      .photo {
        margin-top: $d64px;
        width: $d450px;
        height: $d430px;
        border-radius: $d50px;
        background-image: url(../images/s1_photo.jpg);
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        @include mob {
          border-radius: $m15px;
          margin-top: $m60px;
          width: $m355px;
          height: $m340px;
        }
      }
      .p2dub {
        font-family: "Druk Text Wide Cyr";
        font-style: normal;
        font-weight: 500;
        font-size: $m17px;
        line-height: 115%;
        color: #f18666;
        margin-top: $m20px;
        display: none;
        @include mob {
          display: block;
        }
      }
    }
  }
}
.sTitle {
  font-family: "Druk Text Wide Cyr";
  font-style: normal;
  font-weight: 500;
  font-size: $d36px;
  line-height: 115%;
  text-transform: uppercase;
  @include mob {
    font-size: $m20px;
  }
}
.howtouse {
  width: 100%;
  background: #be9fc9;
  position: relative;
  padding-top: $d75px;
  padding-bottom: $d100px;
  @include mob {
    padding-top: $m40px;
    padding-bottom: $m64px;
  }

  .sphereMob {
    position: absolute;
    width: $m90px;
    height: $m90px;
    right: $m30px;
    top: -$m20px;
    background-image: url(../images/sphere.svg);
    background-position: top left;
    background-size: 100%;
    background-repeat: no-repeat;
    display: none;
    @include mob {
      display: block;
    }
  }
  .raw {
    display: grid;
    grid-template-columns: $d500px 1fr;
    gap: $d100px;
    @include mob {
      display: block;
    }
    .lCol {
      @include mob {
        display: none;
      }
      .photo {
        width: $d500px;
        height: $d700px;
        border-radius: $d50px;
        background-image: url(../images/s2_photo.jpg);
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        position: relative;
        .sphere {
          position: absolute;
          width: $d130px;
          height: $d130px;
          left: -$d66px;
          top: -$d55px;
          background-image: url(../images/sphere.svg);
          background-position: top left;
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .rCol {
      padding-top: $d98px;
      @include mob {
        padding-top: 0;
      }
      .sTitle {
        color: #000000;
        margin-bottom: $d77px;
        @include mob {
          margin-bottom: $m20px;
        }
      }
      .items {
        .item {
          display: grid;
          grid-template-columns: $d50px 1fr;
          gap: $d25px;
          @include mob {
            grid-template-columns: $m30px 1fr;
            gap: $m25px;
          }
          .icon {
            .i1,
            .i2,
            .i3 {
              width: $d50px;
              height: $d50px;
              background-position: top left;
              background-size: 100%;
              background-repeat: no-repeat;
              @include mob {
                width: $m30px;
                height: $m30px;
              }
            }
            .i1 {
              background-image: url(../images/s2_i1.svg);
            }
            .i2 {
              background-image: url(../images/s2_i2.svg);
            }
            .i3 {
              background-image: url(../images/s2_i3.svg);
            }
          }
          .text {
            font-size: $d20px;
            line-height: 135%;
            color: #1a1a1a;
            @include mob {
              font-size: $m17px;
              padding-right: $m20px;
            }
          }
          & + .item {
            margin-top: $d25px;
            @include mob {
              margin-top: $m20px;
            }
          }
        }
      }
    }
  }
}

.business {
  padding-top: $d100px;
  padding-bottom: $d100px;
  background-color: #fff;

  @include mob {
    padding-top: $m64px;
    padding-bottom: $m106px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mob {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__points {
    padding-top: $d72px;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: $d64px;
    @include mob {
      padding-top: $m32px;
      row-gap: $m32px;
    }
  }

  &__btn {
    @include mob {
      width: 100%;
      margin-top: $m32px;
    }
  }

  &__button {
    padding-top: $d64px;
    display: flex;
    justify-content: center;
    @include mob {
      padding-top: $m32px;
      .btn {
        width: 100%;
      }
    }
  }

  .sTitle {
    color: #4bb064;
  }
}

.point {
  border-radius: 50px;
  border: $d2px solid #4bb064;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: $d100px;
  overflow: hidden;
  @include mob {
    border-width: $m2px;
    grid-template-columns: minmax(0, 1fr);
    row-gap: $m32px;
  }

  &__title {
    color: #f18666;
    font-family: "Druk Text Wide Cyr", sans-serif;
    font-size: $d24px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    @include mob {
      font-size: $m17px;
    }
  }

  &__text {
    padding-top: $d24px;
    padding-left: $d60px;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    font-size: $d20px;
    @include mob {
      padding: $m20px $m16px 0 $m39px;
      font-size: $m16px;
    }
  }

  &__img {
    width: 100%;
    min-height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &_service {
      background-image: url("../images/point-service.png");
      aspect-ratio: 550 / 703;
      border-radius: 50px 0 0 50px;
      @include mob {
        aspect-ratio: 355 / 300;
        border-radius: 0 0 50px 50px;
      }
    }

    &_resycling {
      background-image: url("../images/recycle.png");
      aspect-ratio: 549 / 304;
      border-radius: 0 50px 50px 0;
      @include mob {
        aspect-ratio: 355 / 300;
        border-radius: 0 0 50px 50px;
      }
    }

    &_forest {
      background-image: url("../images/point-forest.png");
      aspect-ratio: 550 / 290;
      border-radius: 50px 0 0 50px;
      @include mob {
        aspect-ratio: 355 / 300;
        border-radius: 0 0 50px 50px;
      }
    }

    &_pr {
      background-image: url("../images/point-pr.png");
      aspect-ratio: 549 / 291;
      border-radius: 0 50px 50px 0;
      @include mob {
        aspect-ratio: 355 / 300;
        border-radius: 0 0 50px 50px;
      }
    }
  }

  &__partner {
    padding-top: $d48px;
    padding-left: $d60px;
    display: flex;
    align-items: center;
    @include mob {
      padding-top: $m24px;
      padding-left: $m39px;
    }

    &-link {
      display: inline-block;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;

      &_sborka {
        width: $d118px;
        height: $d44px;
        background-image: url("../images/sborka-logo.png");
        @include mob {
          width: $m86px;
          height: $m32px;
        }
      }

      &_vk {
        width: $d209px;
        height: $d44px;
        background-image: url("../images/vk-logo.png");
        @include mob {
          width: $m152px;
          height: $m32px;
        }
      }
    }

    p {
      padding-right: $d16px;
      color: #1a1a1a;
      font-family: Arial, sans-serif;
      font-size: $d16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @include mob {
        padding-right: $m8px;
        font-size: $m14px;
      }
    }
  }
}

.service {
  &__left {
    padding: $d40px 0 $d40px $d40px;

    @include mob {
      grid-row: 2;
      padding: 0 0 $m32px $m16px;
    }
  }

  &__list {
    padding-top: $d32px;
    padding-left: $d88px;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: $d16px;
    @include mob {
      padding-top: $m24px;
      padding-left: $m55px;
      padding-right: $m30px;
      row-gap: $m16px;
    }
  }

  &__list-item {
    position: relative;
    color: #4d4d4d;
    font-family: Arial, sans-serif;
    font-size: $d16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @include mob {
      font-size: $m14px;
    }

    &::after {
      content: "";
      display: inline-block;
      width: $d16px;
      height: $d16px;
      border-radius: 50%;
      background-color: #4bb064;
      position: absolute;
      top: 0;
      left: -1.94444444444vw;
      @include mob {
        width: $m8px;
        height: $m8px;
        left: -4.26666666667vw;
        top: $m4px;
      }
    }
  }

  &__and {
    padding-top: $d44px;
    padding-left: $d88px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include mob {
      padding-top: $m32px;
      padding-left: $m39px;
    }

    p {
      color: #000;
      font-family: "Druk Text Wide Cyr", sans-serif;
      font-size: $d17px;
      font-style: normal;
      font-weight: 500;
      line-height: 115%; /* 19.55px */
      padding-right: $d16px;
      @include mob {
        font-size: $m17px;
        padding-right: $m16px;
      }
    }
  }
}

.recycling {
  .right {
    padding: $d54px $d140px $d40px 0;
  }
  @include mob {
    padding: 0 0 $m32px $m16px;
  }
}

.forest {
  .left {
    padding: $d40px 0 $d40px $d40px;
    @include mob {
      grid-row: 2;
      padding: 0 0 $m32px $m16px;
    }
  }
}

.pr {
  .right {
    padding: $d40px $d40px $d40px 0;
    @include mob {
      padding: 0 $m16px $m32px $m16px;
    }
  }
}

.corp {
  width: 100%;
  background: #ffffff;
  position: relative;
  padding-top: $d100px;
  padding-bottom: $d100px;
  @include mob {
    padding-top: $m40px;
    padding-bottom: $m65px;
  }
  .raw {
    display: grid;
    grid-template-columns: 1fr $d450px;
    gap: $d100px;
    @include mob {
      display: flex;
      flex-direction: column;
    }
    .lCol {
      @include mob {
        order: 2;
      }
      .sTitle {
        margin-bottom: $d50px;
        color: #4bb064;
        @include mob {
          margin-bottom: $m20px;
        }
      }
      .pBox {
        padding-left: $d100px;
        padding-right: $d50px;
        @include mob {
          padding-left: 0;
          padding-right: 0;
        }
        .p1 {
          font-size: $d20px;
          line-height: 135%;
          color: #1a1a1a;
          @include mob {
            font-size: $m16px;
          }
        }
        .p2 {
          font-size: $d20px;
          line-height: 135%;
          color: #1a1a1a;
          margin-top: $d20px;
          margin-bottom: $d30px;
          @include mob {
            font-size: $m16px;
            margin-top: $m15px;
            margin-bottom: $m20px;
          }
          ul {
            list-style-position: outside;
            padding-left: $d20px;
            @include mob {
              padding-left: $m20px;
            }
            li {
              & + li {
                margin-top: $d10px;
                @include mob {
                  margin-top: $m10px;
                }
              }
            }
          }
        }
        .p3 {
          font-size: $d16px;
          line-height: $d20px;
          color: #1a1a1a;
          margin-top: $d30px;
          margin-bottom: $d10px;
          font-family: "Druk Text Wide Cyr";
          font-weight: 500;
          color: #1a1a1a;
          @include mob {
            font-size: $m14px;
            line-height: $m18px;
            margin-top: $m20px;
            margin-bottom: $m10px;
          }
        }
        .contact {
          margin-top: $d5px;
          @include mob {
            margin-top: $m5px;
          }
          .title {
            font-size: $d16px;
            line-height: $d18px;
            color: #808080;
            margin-bottom: $d10px;
            @include mob {
              font-size: $m14px;
              line-height: $m16px;
              margin-bottom: $m5px;
            }
          }
          .goal,
          .goal a {
            font-family: "Arial";
            font-style: normal;
            font-weight: 400;
            font-size: $d20px;
            line-height: 135%;
            color: #1a1a1a;
            @include mob {
              font-size: $m14px;
              line-height: $m18px;
            }
          }
        }
      }
    }
    .rCol {
      @include mob {
        order: 1;
      }
      .photo {
        width: $d450px;
        height: $d525px;
        border-radius: $d50px;
        background-image: url(../images/s4_photo.jpg);
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        position: relative;
        @include mob {
          background-image: url(../images/m_s4_photo.jpg);
          border-radius: $m15px;
          width: $m355px;
          height: $m426px;
        }
      }
    }
  }
}
footer {
  padding-top: $d25px;
  padding-bottom: $d40px;
  background: #ffffff;
  @include mob {
    padding-top: $m30px;
    padding-bottom: $m30px;
  }
  .raw {
    display: grid;
    grid-template-columns: $d496px 1fr $d200px;
    gap: 0;
    @include mob {
      display: block;
    }
    .logo {
      width: $d313px;
      height: $d25px;
      background-image: url(../images/logo_footer.svg);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      @include mob {
        width: $m184px;
        height: $m15px;
        margin-bottom: $m20px;
      }
    }
    .footer_menu {
      margin: 0;
      padding: 0;
      li {
        display: block;
        a {
          font-size: $d16px;
          line-height: $d18px;
          color: #4d4d4d;
          opacity: 0.8;
          @include mob {
            font-size: $m14px;
            line-height: $m16px;
          }
        }
        & + li {
          margin-top: $d14px;
          @include mob {
            margin-top: $m10px;
          }
        }
      }
    }
    .impactCol {
      text-align: right;
      color: #4d4d4d;
      @include mob {
        text-align: left;
        margin-top: $m20px;
      }
      .impactText {
        font-size: $d16px;
        display: inline-block;
        opacity: 0.8;
        height: $d18px;
        line-height: $d18px;
        vertical-align: baseline;
        @include mob {
          font-size: $m14px;
          line-height: $m16px;
        }
      }
      .impact {
        display: inline-block;
        width: $d64px;
        height: $d18px;
        background-image: url(../images/impact.svg);
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-left: $d10px;
        @include mob {
          margin-left: $m10px;
          width: $m61px;
          height: $m16px;
        }
      }
    }
  }
}
.create {
  width: 100%;
  background: #fff6e0;
  position: relative;
  padding-top: $d75px;
  padding-bottom: $d100px;
  @include mob {
    padding-top: $m40px;
    padding-bottom: $m60px;
  }
  .container {
    position: relative;
  }
  .recycle {
    position: absolute;
    right: 0;
    top: 0;
    width: $d130px;
    height: $d130px;
    background-image: url(../images/recycle.svg);
    background-position: top left;
    background-size: 100%;
    background-repeat: no-repeat;
    @include mob {
      width: $m90px;
      height: $m90px;
      right: $m20px;
      top: -$m90px;
    }
  }
  .sTitle {
    color: #4bb064;
  }
  .pBox {
    padding-left: $d100px;
    padding-right: $d200px;
    margin-top: $d25px;
    @include mob {
      margin-top: $m10px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .description {
    font-size: $d20px;
    line-height: 135%;
    color: #1a1a1a;
    @include mob {
      font-size: $m17px;
    }
  }
  .raw {
    margin-top: $d50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $d100px;
    margin-bottom: $d10px;
    @include mob {
      grid-template-columns: 1fr;
      gap: $m10px;
      margin-top: $m30px;
      margin-bottom: $m10px;
    }
  }
  .buttonDescription {
    font-size: $d12px;
    line-height: $d14px;
    text-align: center;
    color: #808080;
    margin-top: $d15px;
    @include mob {
      margin-top: $m10px;
      font-size: $m10px;
      line-height: $m11px;
    }
    a {
      color: #808080;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .modalCrop {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .shadow {
      display: block;
      background: rgba(190, 159, 201, 0.3);
    }
    .modalContainer {
      position: relative;
      z-index: 101;
      width: $d400px;
      background: #ffffff;
      border: $d2px solid #be9fc9;
      border-radius: $d25px;
      padding: $d30px;
      @include mob {
        width: $m355px;
        border: $m2px solid #be9fc9;
        border-radius: $m20px;
        padding: $m15px;
      }
      .header {
        font-family: "Druk Text Wide Cyr";
        font-style: normal;
        font-weight: 500;
        font-size: $d16px;
        line-height: $d20px;
        color: #1a1a1a;
        position: relative;
        margin-bottom: $d30px;
        @include mob {
          font-size: $m14px;
          line-height: $m18px;
          margin-bottom: $m15px;
          padding-top: $m3px;
        }
        .close {
          position: absolute;
          right: 0;
          top: -$d2px;
          width: $d24px;
          height: $d24px;
          background-image: url(../images/close-modal.svg);
          background-position: top left;
          background-size: 100%;
          background-repeat: no-repeat;
          display: block;
          cursor: pointer;
          @include mob {
            top: 0;
            width: $m24px;
            height: $m24px;
          }
        }
      }
      .cropper {
        margin-bottom: $d20px;
        @include mob {
          margin-bottom: $m15px;
        }
      }
    }
  }

  .modalAlert {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .shadow {
      display: block;
      background: rgba(190, 159, 201, 0.3);
    }
    .modalContainer {
      position: relative;
      z-index: 101;
      width: $d435px;
      background: #ffffff;
      border: $d2px solid #be9fc9;
      border-radius: $d25px;
      padding: $d30px;
      @include mob {
        width: $m355px;
        border: $m2px solid #be9fc9;
        border-radius: $m25px;
        padding: $m15px;
      }
      .header {
        font-family: "Druk Text Wide Cyr";
        font-style: normal;
        font-weight: 500;
        font-size: $d16px;
        line-height: $d20px;
        color: #1a1a1a;
        position: relative;
        margin-bottom: $d20px;
        @include mob {
          font-size: $m14px;
          line-height: $m18px;
          margin-bottom: $m15px;
          padding-top: $m3px;
        }
        .close {
          position: absolute;
          right: 0;
          top: -$d2px;
          width: $d24px;
          height: $d24px;
          background-image: url(../images/close-modal.svg);
          background-position: top left;
          background-size: 100%;
          background-repeat: no-repeat;
          display: block;
          cursor: pointer;
          @include mob {
            top: 0;
            width: $m24px;
            height: $m24px;
          }
        }
      }
      .modalText {
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: $d16px;
        line-height: $d18px;
        color: #4d4d4d;
        margin-bottom: $d20px;
        @include mob {
          font-size: $m14px;
          line-height: $m16px;
          margin-bottom: $m15px;
        }
      }
    }
  }
}

.button {
  height: $d60px;
  background: #be9fc9;
  border-radius: 100px;
  border: $d2px solid #be9fc9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Druk Text Wide Cyr";
  font-style: normal;
  font-weight: 500;
  font-size: $d16px;
  line-height: $d20px;
  color: #000000;
  @include mob {
    border: 2px solid #be9fc9;
    height: $m58px;
    font-size: $m14px;
    line-height: $m18px;
  }
  &:hover {
    background: none;
    text-decoration: none;
    color: #000000;
  }
  &.light {
    background: none;
    &:hover {
      background: #be9fc9;
      color: #000000;
    }
  }
  & + .button {
    margin-top: $d15px;
    @include mob {
      margin-top: $m10px;
    }
  }
}
.buttonBlack {
  height: $d43px;
  background: #000000;
  border-radius: $d15px;
  border: $d2px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Druk Text Wide Cyr";
  font-style: normal;
  font-weight: 500;
  font-size: $d10px;
  line-height: $d13px;
  text-align: center;
  color: #ffffff;
  @include mob {
    border: 2px solid #000000;
    height: $m43px;
    font-size: $m10px;
    line-height: $m13px;
    border-radius: $m15px;
  }
  &:hover {
    background: none;
    text-decoration: none;
    color: #000000;
  }
  &.light {
    background: none;
    color: #000000;
    &:hover {
      background: #000000;
      color: #ffffff;
    }
  }
  & + .button {
    margin-top: $d15px;
    @include mob {
      margin-top: $m10px;
    }
  }
  &.text_color_light {
    color: #ffffff;
    &.light {
      color: #000000;
      &:hover {
        color: #ffffff;
      }
    }
  }
  &.text_color_black {
    color: #000000;
    &.light {
      color: #ffffff;
      &:hover {
        color: #000000;
      }
    }
  }

  &.color_000000 {
    background: #000000;
    border: $d2px solid #000000;
    &:hover {
      color: #000000;
      background: none;
    }
    &.light {
      color: #000000;
      background: none;
      &:hover {
        background: #000000;
      }
    }
  }
  &.color_434343 {
    background: #434343;
    border: $d2px solid #434343;
    &:hover {
      color: #434343;
      background: none;
    }
    &.light {
      color: #434343;
      background: none;
      &:hover {
        background: #434343;
      }
    }
  }
  &.color_666666 {
    background: #666666;
    border: $d2px solid #666666;
    &:hover {
      color: #666666;
      background: none;
    }
    &.light {
      color: #666666;
      background: none;
      &:hover {
        background: #666666;
      }
    }
  }
  &.color_999999 {
    background: #999999;
    border: $d2px solid #999999;
    &:hover {
      color: #999999;
      background: none;
    }
    &.light {
      color: #999999;
      background: none;
      &:hover {
        background: #999999;
      }
    }
  }
  &.color_B7B7B7 {
    background: #b7b7b7;
    border: $d2px solid #b7b7b7;
    &:hover {
      color: #b7b7b7;
      background: none;
    }
    &.light {
      color: #b7b7b7;
      background: none;
      &:hover {
        background: #b7b7b7;
      }
    }
  }
  &.color_CCCCCC {
    background: #cccccc;
    border: $d2px solid #cccccc;
    &:hover {
      color: #cccccc;
      background: none;
    }
    &.light {
      color: #cccccc;
      background: none;
      &:hover {
        background: #cccccc;
      }
    }
  }
  &.color_D9D9D9 {
    background: #d9d9d9;
    border: $d2px solid #d9d9d9;
    &:hover {
      color: #d9d9d9;
      background: none;
    }
    &.light {
      color: #d9d9d9;
      background: none;
      &:hover {
        background: #d9d9d9;
      }
    }
  }
  &.color_EFEFEF {
    background: #efefef;
    border: $d2px solid #efefef;
    &:hover {
      color: #efefef;
      background: none;
    }
    &.light {
      color: #efefef;
      background: none;
      &:hover {
        background: #efefef;
      }
    }
  }
  &.color_F3F3F3 {
    background: #f3f3f3;
    border: $d2px solid #f3f3f3;
    &:hover {
      color: #f3f3f3;
      background: none;
    }
    &.light {
      color: #f3f3f3;
      background: none;
      &:hover {
        background: #f3f3f3;
      }
    }
  }
  &.color_FFFFFF {
    background: #ffffff;
    border: $d2px solid #ffffff;
    &:hover {
      color: #ffffff;
      background: none;
    }
    &.light {
      color: #ffffff;
      background: none;
      &:hover {
        background: #ffffff;
      }
    }
  }
  &.color_980000 {
    background: #980000;
    border: $d2px solid #980000;
    &:hover {
      color: #980000;
      background: none;
    }
    &.light {
      color: #980000;
      background: none;
      &:hover {
        background: #980000;
      }
    }
  }
  &.color_FF0000 {
    background: #ff0000;
    border: $d2px solid #ff0000;
    &:hover {
      color: #ff0000;
      background: none;
    }
    &.light {
      color: #ff0000;
      background: none;
      &:hover {
        background: #ff0000;
      }
    }
  }
  &.color_FF9900 {
    background: #ff9900;
    border: $d2px solid #ff9900;
    &:hover {
      color: #ff9900;
      background: none;
    }
    &.light {
      color: #ff9900;
      background: none;
      &:hover {
        background: #ff9900;
      }
    }
  }
  &.color_FFFF00 {
    background: #ffff00;
    border: $d2px solid #ffff00;
    &:hover {
      color: #ffff00;
      background: none;
    }
    &.light {
      color: #ffff00;
      background: none;
      &:hover {
        background: #ffff00;
      }
    }
  }
  &.color_00FF00 {
    background: #00ff00;
    border: $d2px solid #00ff00;
    &:hover {
      color: #00ff00;
      background: none;
    }
    &.light {
      color: #00ff00;
      background: none;
      &:hover {
        background: #00ff00;
      }
    }
  }
  &.color_00FFFF {
    background: #00ffff;
    border: $d2px solid #00ffff;
    &:hover {
      color: #00ffff;
      background: none;
    }
    &.light {
      color: #00ffff;
      background: none;
      &:hover {
        background: #00ffff;
      }
    }
  }
  &.color_4A86E8 {
    background: #4a86e8;
    border: $d2px solid #4a86e8;
    &:hover {
      color: #4a86e8;
      background: none;
    }
    &.light {
      color: #4a86e8;
      background: none;
      &:hover {
        background: #4a86e8;
      }
    }
  }
  &.color_0000FF {
    background: #0000ff;
    border: $d2px solid #0000ff;
    &:hover {
      color: #0000ff;
      background: none;
    }
    &.light {
      color: #0000ff;
      background: none;
      &:hover {
        background: #0000ff;
      }
    }
  }
  &.color_9900FF {
    background: #9900ff;
    border: $d2px solid #9900ff;
    &:hover {
      color: #9900ff;
      background: none;
    }
    &.light {
      color: #9900ff;
      background: none;
      &:hover {
        background: #9900ff;
      }
    }
  }
  &.color_FF00FF {
    background: #ff00ff;
    border: $d2px solid #ff00ff;
    &:hover {
      color: #ff00ff;
      background: none;
    }
    &.light {
      color: #ff00ff;
      background: none;
      &:hover {
        background: #ff00ff;
      }
    }
  }
  &.color_E6B8AF {
    background: #e6b8af;
    border: $d2px solid #e6b8af;
    &:hover {
      color: #e6b8af;
      background: none;
    }
    &.light {
      color: #e6b8af;
      background: none;
      &:hover {
        background: #e6b8af;
      }
    }
  }
  &.color_F4CCCC {
    background: #f4cccc;
    border: $d2px solid #f4cccc;
    &:hover {
      color: #f4cccc;
      background: none;
    }
    &.light {
      color: #f4cccc;
      background: none;
      &:hover {
        background: #f4cccc;
      }
    }
  }
  &.color_FCE5CD {
    background: #fce5cd;
    border: $d2px solid #fce5cd;
    &:hover {
      color: #fce5cd;
      background: none;
    }
    &.light {
      color: #fce5cd;
      background: none;
      &:hover {
        background: #fce5cd;
      }
    }
  }
  &.color_FFF2CC {
    background: #fff2cc;
    border: $d2px solid #fff2cc;
    &:hover {
      color: #fff2cc;
      background: none;
    }
    &.light {
      color: #fff2cc;
      background: none;
      &:hover {
        background: #fff2cc;
      }
    }
  }
  &.color_D9EAD3 {
    background: #d9ead3;
    border: $d2px solid #d9ead3;
    &:hover {
      color: #d9ead3;
      background: none;
    }
    &.light {
      color: #d9ead3;
      background: none;
      &:hover {
        background: #d9ead3;
      }
    }
  }
  &.color_D0E0E3 {
    background: #d0e0e3;
    border: $d2px solid #d0e0e3;
    &:hover {
      color: #d0e0e3;
      background: none;
    }
    &.light {
      color: #d0e0e3;
      background: none;
      &:hover {
        background: #d0e0e3;
      }
    }
  }
  &.color_C9DAF8 {
    background: #c9daf8;
    border: $d2px solid #c9daf8;
    &:hover {
      color: #c9daf8;
      background: none;
    }
    &.light {
      color: #c9daf8;
      background: none;
      &:hover {
        background: #c9daf8;
      }
    }
  }
  &.color_CFE2F3 {
    background: #cfe2f3;
    border: $d2px solid #cfe2f3;
    &:hover {
      color: #cfe2f3;
      background: none;
    }
    &.light {
      color: #cfe2f3;
      background: none;
      &:hover {
        background: #cfe2f3;
      }
    }
  }
  &.color_D9D2E9 {
    background: #d9d2e9;
    border: $d2px solid #d9d2e9;
    &:hover {
      color: #d9d2e9;
      background: none;
    }
    &.light {
      color: #d9d2e9;
      background: none;
      &:hover {
        background: #d9d2e9;
      }
    }
  }
  &.color_EAD1DC {
    background: #ead1dc;
    border: $d2px solid #ead1dc;
    &:hover {
      color: #ead1dc;
      background: none;
    }
    &.light {
      color: #ead1dc;
      background: none;
      &:hover {
        background: #ead1dc;
      }
    }
  }
  &.color_DD7E6B {
    background: #dd7e6b;
    border: $d2px solid #dd7e6b;
    &:hover {
      color: #dd7e6b;
      background: none;
    }
    &.light {
      color: #dd7e6b;
      background: none;
      &:hover {
        background: #dd7e6b;
      }
    }
  }
  &.color_EA9999 {
    background: #ea9999;
    border: $d2px solid #ea9999;
    &:hover {
      color: #ea9999;
      background: none;
    }
    &.light {
      color: #ea9999;
      background: none;
      &:hover {
        background: #ea9999;
      }
    }
  }
  &.color_F9CB9C {
    background: #f9cb9c;
    border: $d2px solid #f9cb9c;
    &:hover {
      color: #f9cb9c;
      background: none;
    }
    &.light {
      color: #f9cb9c;
      background: none;
      &:hover {
        background: #f9cb9c;
      }
    }
  }
  &.color_FFE599 {
    background: #ffe599;
    border: $d2px solid #ffe599;
    &:hover {
      color: #ffe599;
      background: none;
    }
    &.light {
      color: #ffe599;
      background: none;
      &:hover {
        background: #ffe599;
      }
    }
  }
  &.color_B6D7A8 {
    background: #b6d7a8;
    border: $d2px solid #b6d7a8;
    &:hover {
      color: #b6d7a8;
      background: none;
    }
    &.light {
      color: #b6d7a8;
      background: none;
      &:hover {
        background: #b6d7a8;
      }
    }
  }
  &.color_A2C4C9 {
    background: #a2c4c9;
    border: $d2px solid #a2c4c9;
    &:hover {
      color: #a2c4c9;
      background: none;
    }
    &.light {
      color: #a2c4c9;
      background: none;
      &:hover {
        background: #a2c4c9;
      }
    }
  }
  &.color_A4C2F4 {
    background: #a4c2f4;
    border: $d2px solid #a4c2f4;
    &:hover {
      color: #a4c2f4;
      background: none;
    }
    &.light {
      color: #a4c2f4;
      background: none;
      &:hover {
        background: #a4c2f4;
      }
    }
  }
  &.color_9FC5E8 {
    background: #9fc5e8;
    border: $d2px solid #9fc5e8;
    &:hover {
      color: #9fc5e8;
      background: none;
    }
    &.light {
      color: #9fc5e8;
      background: none;
      &:hover {
        background: #9fc5e8;
      }
    }
  }
  &.color_B4A7D6 {
    background: #b4a7d6;
    border: $d2px solid #b4a7d6;
    &:hover {
      color: #b4a7d6;
      background: none;
    }
    &.light {
      color: #b4a7d6;
      background: none;
      &:hover {
        background: #b4a7d6;
      }
    }
  }
  &.color_D5A6BD {
    background: #d5a6bd;
    border: $d2px solid #d5a6bd;
    &:hover {
      color: #d5a6bd;
      background: none;
    }
    &.light {
      color: #d5a6bd;
      background: none;
      &:hover {
        background: #d5a6bd;
      }
    }
  }
  &.color_CC4125 {
    background: #cc4125;
    border: $d2px solid #cc4125;
    &:hover {
      color: #cc4125;
      background: none;
    }
    &.light {
      color: #cc4125;
      background: none;
      &:hover {
        background: #cc4125;
      }
    }
  }
  &.color_E06666 {
    background: #e06666;
    border: $d2px solid #e06666;
    &:hover {
      color: #e06666;
      background: none;
    }
    &.light {
      color: #e06666;
      background: none;
      &:hover {
        background: #e06666;
      }
    }
  }
  &.color_F6B26B {
    background: #f6b26b;
    border: $d2px solid #f6b26b;
    &:hover {
      color: #f6b26b;
      background: none;
    }
    &.light {
      color: #f6b26b;
      background: none;
      &:hover {
        background: #f6b26b;
      }
    }
  }
  &.color_FFD966 {
    background: #ffd966;
    border: $d2px solid #ffd966;
    &:hover {
      color: #ffd966;
      background: none;
    }
    &.light {
      color: #ffd966;
      background: none;
      &:hover {
        background: #ffd966;
      }
    }
  }
  &.color_93C47D {
    background: #93c47d;
    border: $d2px solid #93c47d;
    &:hover {
      color: #93c47d;
      background: none;
    }
    &.light {
      color: #93c47d;
      background: none;
      &:hover {
        background: #93c47d;
      }
    }
  }
  &.color_76A5AF {
    background: #76a5af;
    border: $d2px solid #76a5af;
    &:hover {
      color: #76a5af;
      background: none;
    }
    &.light {
      color: #76a5af;
      background: none;
      &:hover {
        background: #76a5af;
      }
    }
  }
  &.color_6D9EEB {
    background: #6d9eeb;
    border: $d2px solid #6d9eeb;
    &:hover {
      color: #6d9eeb;
      background: none;
    }
    &.light {
      color: #6d9eeb;
      background: none;
      &:hover {
        background: #6d9eeb;
      }
    }
  }
  &.color_6FA8DC {
    background: #6fa8dc;
    border: $d2px solid #6fa8dc;
    &:hover {
      color: #6fa8dc;
      background: none;
    }
    &.light {
      color: #6fa8dc;
      background: none;
      &:hover {
        background: #6fa8dc;
      }
    }
  }
  &.color_8E7CC3 {
    background: #8e7cc3;
    border: $d2px solid #8e7cc3;
    &:hover {
      color: #8e7cc3;
      background: none;
    }
    &.light {
      color: #8e7cc3;
      background: none;
      &:hover {
        background: #8e7cc3;
      }
    }
  }
  &.color_C27BA0 {
    background: #c27ba0;
    border: $d2px solid #c27ba0;
    &:hover {
      color: #c27ba0;
      background: none;
    }
    &.light {
      color: #c27ba0;
      background: none;
      &:hover {
        background: #c27ba0;
      }
    }
  }
  &.color_A61C00 {
    background: #a61c00;
    border: $d2px solid #a61c00;
    &:hover {
      color: #a61c00;
      background: none;
    }
    &.light {
      color: #a61c00;
      background: none;
      &:hover {
        background: #a61c00;
      }
    }
  }
  &.color_CC0000 {
    background: #cc0000;
    border: $d2px solid #cc0000;
    &:hover {
      color: #cc0000;
      background: none;
    }
    &.light {
      color: #cc0000;
      background: none;
      &:hover {
        background: #cc0000;
      }
    }
  }
  &.color_E69138 {
    background: #e69138;
    border: $d2px solid #e69138;
    &:hover {
      color: #e69138;
      background: none;
    }
    &.light {
      color: #e69138;
      background: none;
      &:hover {
        background: #e69138;
      }
    }
  }
  &.color_F1C232 {
    background: #f1c232;
    border: $d2px solid #f1c232;
    &:hover {
      color: #f1c232;
      background: none;
    }
    &.light {
      color: #f1c232;
      background: none;
      &:hover {
        background: #f1c232;
      }
    }
  }
  &.color_6AA84F {
    background: #6aa84f;
    border: $d2px solid #6aa84f;
    &:hover {
      color: #6aa84f;
      background: none;
    }
    &.light {
      color: #6aa84f;
      background: none;
      &:hover {
        background: #6aa84f;
      }
    }
  }
  &.color_45818E {
    background: #45818e;
    border: $d2px solid #45818e;
    &:hover {
      color: #45818e;
      background: none;
    }
    &.light {
      color: #45818e;
      background: none;
      &:hover {
        background: #45818e;
      }
    }
  }
  &.color_3C78D8 {
    background: #3c78d8;
    border: $d2px solid #3c78d8;
    &:hover {
      color: #3c78d8;
      background: none;
    }
    &.light {
      color: #3c78d8;
      background: none;
      &:hover {
        background: #3c78d8;
      }
    }
  }
  &.color_3D85C6 {
    background: #3d85c6;
    border: $d2px solid #3d85c6;
    &:hover {
      color: #3d85c6;
      background: none;
    }
    &.light {
      color: #3d85c6;
      background: none;
      &:hover {
        background: #3d85c6;
      }
    }
  }
  &.color_674EA7 {
    background: #674ea7;
    border: $d2px solid #674ea7;
    &:hover {
      color: #674ea7;
      background: none;
    }
    &.light {
      color: #674ea7;
      background: none;
      &:hover {
        background: #674ea7;
      }
    }
  }
  &.color_A64D79 {
    background: #a64d79;
    border: $d2px solid #a64d79;
    &:hover {
      color: #a64d79;
      background: none;
    }
    &.light {
      color: #a64d79;
      background: none;
      &:hover {
        background: #a64d79;
      }
    }
  }
  &.color_85200C {
    background: #85200c;
    border: $d2px solid #85200c;
    &:hover {
      color: #85200c;
      background: none;
    }
    &.light {
      color: #85200c;
      background: none;
      &:hover {
        background: #85200c;
      }
    }
  }
  &.color_990000 {
    background: #990000;
    border: $d2px solid #990000;
    &:hover {
      color: #990000;
      background: none;
    }
    &.light {
      color: #990000;
      background: none;
      &:hover {
        background: #990000;
      }
    }
  }
  &.color_B45F06 {
    background: #b45f06;
    border: $d2px solid #b45f06;
    &:hover {
      color: #b45f06;
      background: none;
    }
    &.light {
      color: #b45f06;
      background: none;
      &:hover {
        background: #b45f06;
      }
    }
  }
  &.color_BF9000 {
    background: #bf9000;
    border: $d2px solid #bf9000;
    &:hover {
      color: #bf9000;
      background: none;
    }
    &.light {
      color: #bf9000;
      background: none;
      &:hover {
        background: #bf9000;
      }
    }
  }
  &.color_38761D {
    background: #38761d;
    border: $d2px solid #38761d;
    &:hover {
      color: #38761d;
      background: none;
    }
    &.light {
      color: #38761d;
      background: none;
      &:hover {
        background: #38761d;
      }
    }
  }
  &.color_134F5C {
    background: #134f5c;
    border: $d2px solid #134f5c;
    &:hover {
      color: #134f5c;
      background: none;
    }
    &.light {
      color: #134f5c;
      background: none;
      &:hover {
        background: #134f5c;
      }
    }
  }
  &.color_1155CC {
    background: #1155cc;
    border: $d2px solid #1155cc;
    &:hover {
      color: #1155cc;
      background: none;
    }
    &.light {
      color: #1155cc;
      background: none;
      &:hover {
        background: #1155cc;
      }
    }
  }
  &.color_0B5394 {
    background: #0b5394;
    border: $d2px solid #0b5394;
    &:hover {
      color: #0b5394;
      background: none;
    }
    &.light {
      color: #0b5394;
      background: none;
      &:hover {
        background: #0b5394;
      }
    }
  }
  &.color_351C75 {
    background: #351c75;
    border: $d2px solid #351c75;
    &:hover {
      color: #351c75;
      background: none;
    }
    &.light {
      color: #351c75;
      background: none;
      &:hover {
        background: #351c75;
      }
    }
  }
  &.color_741B47 {
    background: #741b47;
    border: $d2px solid #741b47;
    &:hover {
      color: #741b47;
      background: none;
    }
    &.light {
      color: #741b47;
      background: none;
      &:hover {
        background: #741b47;
      }
    }
  }
  &.color_5B0F00 {
    background: #5b0f00;
    border: $d2px solid #5b0f00;
    &:hover {
      color: #5b0f00;
      background: none;
    }
    &.light {
      color: #5b0f00;
      background: none;
      &:hover {
        background: #5b0f00;
      }
    }
  }
  &.color_660000 {
    background: #660000;
    border: $d2px solid #660000;
    &:hover {
      color: #660000;
      background: none;
    }
    &.light {
      color: #660000;
      background: none;
      &:hover {
        background: #660000;
      }
    }
  }
  &.color_783F04 {
    background: #783f04;
    border: $d2px solid #783f04;
    &:hover {
      color: #783f04;
      background: none;
    }
    &.light {
      color: #783f04;
      background: none;
      &:hover {
        background: #783f04;
      }
    }
  }
  &.color_7F6000 {
    background: #7f6000;
    border: $d2px solid #7f6000;
    &:hover {
      color: #7f6000;
      background: none;
    }
    &.light {
      color: #7f6000;
      background: none;
      &:hover {
        background: #7f6000;
      }
    }
  }
  &.color_274E13 {
    background: #274e13;
    border: $d2px solid #274e13;
    &:hover {
      color: #274e13;
      background: none;
    }
    &.light {
      color: #274e13;
      background: none;
      &:hover {
        background: #274e13;
      }
    }
  }
  &.color_0C343D {
    background: #0c343d;
    border: $d2px solid #0c343d;
    &:hover {
      color: #0c343d;
      background: none;
    }
    &.light {
      color: #0c343d;
      background: none;
      &:hover {
        background: #0c343d;
      }
    }
  }
  &.color_1C4587 {
    background: #1c4587;
    border: $d2px solid #1c4587;
    &:hover {
      color: #1c4587;
      background: none;
    }
    &.light {
      color: #1c4587;
      background: none;
      &:hover {
        background: #1c4587;
      }
    }
  }
  &.color_073763 {
    background: #073763;
    border: $d2px solid #073763;
    &:hover {
      color: #073763;
      background: none;
    }
    &.light {
      color: #073763;
      background: none;
      &:hover {
        background: #073763;
      }
    }
  }
  &.color_20124D {
    background: #20124d;
    border: $d2px solid #20124d;
    &:hover {
      color: #20124d;
      background: none;
    }
    &.light {
      color: #20124d;
      background: none;
      &:hover {
        background: #20124d;
      }
    }
  }
  &.color_4C1130 {
    background: #4c1130;
    border: $d2px solid #4c1130;
    &:hover {
      color: #4c1130;
      background: none;
    }
    &.light {
      color: #4c1130;
      background: none;
      &:hover {
        background: #4c1130;
      }
    }
  }
}
.input {
  position: relative;
  // display: block;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: $d50px;
  background: #fff6e0;
  border: $d2px solid #be9fc9;
  box-sizing: border-box;
  border-radius: $d100px;
  padding: 0 $d15px;
  font-family: "Druk Text Wide Cyr";
  font-style: normal;
  font-weight: 500;
  font-size: $d16px;
  line-height: $d20px;
  color: #1a1a1a;
  outline: none;
  @include mob {
    height: $m48px;
    border: $m2px solid #be9fc9;
    border-radius: $m100px;
    padding: 0 $m15px;
    font-size: $m14px;
    line-height: $m18px;
  }
  &::placeholder {
    color: #1a1a1a;
  }
  &:focus {
    outline: none;
  }
  input[type="file"] {
    display: none;
  }
  &.error {
    border: $d2px solid #f18666;
    @include mob {
      border: $m2px solid #f18666;
    }
  }
  & > .title {
    width: $d260px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include mob {
      width: $m219px;
    }
    .colorIndicator {
      width: $d30px;
      height: $d30px;
      border-radius: $d20px;
      margin-left: $d10px;
      &.border {
        border: 1px solid #d8d8d8;
      }
      @include mob {
        width: $m30px;
        height: $m30px;
        border-radius: $m20px;
        margin-left: $m10px;
      }
    }
  }
  &.color-selector .title {
    width: 100%;
    text-overflow: clip;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  & > .shadow {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    background: none;
    display: block;
  }
  .tools {
    & > * {
      cursor: pointer;
    }
    position: absolute;
    right: $d15px;
    top: $d11px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    @include mob {
      right: $m14px;
      top: $m11px;
    }
    .upload {
      background-image: url(../images/camera.svg);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      width: $d24px;
      height: $d24px;
      @include mob {
        width: $m24px;
        height: $m24px;
      }
    }
    .crop {
      background-image: url(../images/crop.svg);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      width: $d24px;
      height: $d24px;
      margin-left: $d10px;
      @include mob {
        width: $m24px;
        height: $m24px;
        margin-left: $m10px;
      }
    }
    .delete {
      background-image: url(../images/delete.svg);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      width: $d24px;
      height: $d24px;
      margin-left: $d10px;
      @include mob {
        width: $m24px;
        height: $m24px;
        margin-left: $m10px;
      }
    }
    .open {
      background-image: url(../images/up-one.svg);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      width: $d24px;
      height: $d24px;
      @include mob {
        width: $m24px;
        height: $m24px;
      }
    }
    .close {
      background-image: url(../images/down-one.svg);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      width: $d24px;
      height: $d24px;
      @include mob {
        width: $m24px;
        height: $m24px;
      }
    }
  }
  .colorPanel {
    z-index: 101;
    position: absolute;
    left: -$d2px;
    right: -$d2px;
    top: -$d2px;
    height: $d360px;
    padding: $d13px $d15px $d15px $d15px;
    background: #fff6e0;
    border: $d2px solid #be9fc9;
    border-radius: $d25px;
    @include mob {
      left: -$m2px;
      right: -$m2px;
      top: -$m2px;
      height: $m324px;
      padding: $m13px $m15px $m15px $m15px;
      border: $m2px solid #be9fc9;
      border-radius: $m25px;
    }
    .colorsList {
      margin-top: $d21px;
      display: flex;
      gap: $d7px;
      flex-wrap: wrap;
      @include mob {
        margin-top: $m15px;
        gap: $m7px;
      }
      .colorItem {
        width: $d30px;
        height: $d30px;
        background: #000000;
        border-radius: $d20px;
        box-sizing: border-box;
        cursor: pointer;
        @include mob {
          width: $m25px;
          height: $m25px;
          border-radius: $m20px;
        }
        &.border {
          border: 1px solid #d8d8d8;
        }
        &.selected {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          &::before {
            content: "";
            display: block;
            background-image: url(../images/cSelected.svg);
            background-position: top left;
            background-size: 100%;
            background-repeat: no-repeat;
            width: $d16px;
            height: $d12px;
            @include mob {
              width: $m14px;
              height: $m11px;
            }
          }
          &.white {
            &::before {
              background-image: url(../images/cSelectedWhite.svg);
            }
          }
        }
      }
    }
  }
}
.input + .input {
  margin-top: $d10px;
  @include mob {
    margin-top: $m10px;
  }
}

body {
  background: #fff6e0;
  header {
    display: none;
  }
  .sections {
    display: none;
  }
  footer {
    display: none;
  }
}
body.main {
  header {
    display: block;
  }
  .sections {
    display: block;
  }
  footer {
    display: block;
  }
}
body.p404 {
  background: #ffffff;
  header {
    display: block;
  }
}

.mycard {
  width: 100%;
  background: #fff6e0;
  position: relative;
  padding-top: $d75px;
  padding-bottom: $d100px;
  @include mob {
    padding-top: $m40px;
    padding-bottom: $m60px;
  }
  & > .container {
    position: relative;
    .raw {
      margin-top: $d50px;
      display: grid;
      grid-template-columns: 1fr $d450px;
      gap: $d150px;
      margin-bottom: $d10px;
      @include mob {
        grid-template-columns: 1fr;
        gap: $m20px;
        margin-top: $m30px;
        margin-bottom: $m60px;
      }
      .col-1 {
        padding-left: $d100px;
        padding-right: $d100px;
        padding-top: $d70px;
        @include mob {
          order: 2;
          padding-left: 0;
          padding-right: 0;
          padding-top: 0;
        }
      }
      .col-2 {
        @include mob {
          order: 1;
        }
      }
    }
  }
  .sTitle {
    color: #4bb064;
  }
  .qrBox {
    width: $d450px;
    height: $d450px;
    background: #ffffff;
    border-radius: $d50px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mob {
      width: $m355px;
      height: $m355px;
      border-radius: $m50px;
    }
    img {
      width: $d350px;
      height: $d350px;
      @include mob {
        width: $m260px;
        height: $m260px;
      }
    }
  }
  .qrOpenLink {
    padding-top: $d20px;
    text-align: center;
    @include mob {
      padding-top: $m15px;
      padding-bottom: $m20px;
    }
    a {
      font-family: "Arial";
      font-style: normal;
      font-weight: 400;
      font-size: $d16px;
      line-height: $d18px;
      color: #4d4d4d;
      text-decoration: underline;
      @include mob {
        font-size: $m16px;
        line-height: $m18px;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  .share-two {
    width: $d24px;
    height: $d24px;
    margin-left: $d12px;
    background-image: url(../images/share-two.svg);
    background-position: top left;
    background-size: 100%;
    background-repeat: no-repeat;
    @include mob {
      width: $m24px;
      height: $m24px;
      margin-left: $m10px;
    }
  }
  .to-download {
    width: $d24px;
    height: $d24px;
    margin-left: $d10px;
    background-image: url(../images/to-download.svg);
    background-position: top left;
    background-size: 100%;
    background-repeat: no-repeat;
    @include mob {
      width: $m24px;
      height: $m24px;
      margin-left: $m10px;
    }
  }
  .edit-two {
    width: $d24px;
    height: $d24px;
    margin-left: $d10px;
    background-image: url(../images/edit-two.svg);
    background-position: top left;
    background-size: 100%;
    background-repeat: no-repeat;
    @include mob {
      width: $m24px;
      height: $m24px;
      margin-left: $m10px;
    }
  }
  .copy-link {
    width: $d24px;
    height: $d24px;
    margin-left: $d10px;
    background-image: url(../images/copy-link.svg);
    background-position: top left;
    background-size: 100%;
    background-repeat: no-repeat;
    @include mob {
      width: $m24px;
      height: $m24px;
      margin-left: $m10px;
    }
  }
  .delimiter {
    height: $d30px;
    @include mob {
      height: $m30px;
    }
  }
  .boxRec {
    margin-top: $d15px;
    display: grid;
    grid-template-columns: $d16px 1fr;
    gap: $d5px;
    @include mob {
      margin-top: $m10px;
      grid-template-columns: $m14px 1fr;
      gap: $m5px;
    }
    .icon {
      width: $d16px;
      height: $d16px;
      background-image: url(../images/attention.svg);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      @include mob {
        width: $m14px;
        height: $m14px;
      }
    }
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: $d16px;
    line-height: $d18px;
    color: #808080;
    @include mob {
      font-size: $m14px;
      line-height: $m16px;
    }
  }
  .modalDownload {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .shadow {
      display: block;
      background: rgba(190, 159, 201, 0.3);
    }
    .modalContainer {
      position: relative;
      z-index: 101;
      width: $d350px;
      background: #ffffff;
      border: $d2px solid #be9fc9;
      border-radius: $d25px;
      padding: $d30px;
      @include mob {
        width: $m355px;
        border: $m2px solid #be9fc9;
        border-radius: $m20px;
        padding: $m15px;
      }
      .header {
        font-family: "Druk Text Wide Cyr";
        font-style: normal;
        font-weight: 500;
        font-size: $d16px;
        line-height: $d20px;
        color: #1a1a1a;
        position: relative;
        margin-bottom: $d30px;
        @include mob {
          font-size: $m14px;
          line-height: $m18px;
          margin-bottom: $m15px;
          padding-top: $m3px;
        }
        .close {
          position: absolute;
          right: 0;
          top: -$d2px;
          width: $d24px;
          height: $d24px;
          background-image: url(../images/close-modal.svg);
          background-position: top left;
          background-size: 100%;
          background-repeat: no-repeat;
          display: block;
          cursor: pointer;
          @include mob {
            top: 0;
            width: $m24px;
            height: $m24px;
          }
        }
      }
      .links {
        .link {
          height: 49px;
          display: grid;
          grid-template-columns: 1fr $d100px;
          border-bottom: $d1px solid #be9fc9;
          align-items: center;
          @include mob {
            grid-template-columns: 1fr $m100px;
            border-bottom: $m1px solid #be9fc9;
          }
          .title {
            font-family: "Arial";
            font-style: normal;
            font-weight: 400;
            font-size: $d16px;
            line-height: $d18px;
            color: #4d4d4d;
            @include mob {
              font-size: $m14px;
              line-height: $m16px;
            }
          }
          .download {
            text-align: right;
            a {
              font-family: "Druk Text Wide Cyr";
              font-style: normal;
              font-weight: 500;
              font-size: $d12px;
              line-height: $d15px;
              text-transform: uppercase;
              color: #4bb064;
              @include mob {
                font-size: $m10px;
                line-height: $m13px;
              }
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
  .modalShare {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .shadow {
      display: block;
      background: rgba(190, 159, 201, 0.3);
    }
    .modalContainer {
      position: relative;
      z-index: 101;
      width: $d600px;
      background: #ffffff;
      border: $d2px solid #be9fc9;
      border-radius: $d25px;
      padding: $d30px;
      @include mob {
        width: $m355px;
        border: $m2px solid #be9fc9;
        border-radius: $m20px;
        padding: $m30px $m15px;
      }
      .header {
        font-family: "Druk Text Wide Cyr";
        font-style: normal;
        font-weight: 500;
        font-size: $d16px;
        line-height: $d20px;
        color: #1a1a1a;
        position: relative;
        margin-bottom: $d30px;
        @include mob {
          font-size: $m14px;
          line-height: $m18px;
          margin-bottom: $m20px;
          padding-top: $m3px;
        }
        .close {
          position: absolute;
          right: 0;
          top: -$d2px;
          width: $d24px;
          height: $d24px;
          background-image: url(../images/close-modal.svg);
          background-position: top left;
          background-size: 100%;
          background-repeat: no-repeat;
          display: block;
          cursor: pointer;
          @include mob {
            top: 0;
            width: $m24px;
            height: $m24px;
          }
        }
      }
      .links {
        display: grid;
        grid-template-columns: $d96px $d96px $d96px $d96px;
        gap: $d30px;
        padding-bottom: $d30px;
        border-bottom: $d1px solid #be9fc9;
        @include mob {
          grid-template-columns: $m66px $m66px $m66px $m66px;
          gap: $m22px;
          padding-bottom: $m30px;
          border-bottom: $m1px solid #be9fc9;
          justify-content: center;
        }
        a {
          display: block;
          text-decoration: none;
          .icon {
            img {
              width: $d96px;
              height: $d96px;
              @include mob {
                width: $m64px;
                height: $m64px;
              }
            }
            margin-bottom: $d24px;
            @include mob {
              margin-bottom: $m15px;
            }
          }
          .title {
            font-family: "Arial";
            font-style: normal;
            font-weight: 400;
            font-size: $d16px;
            line-height: $d18px;
            @include mob {
              font-size: $m14px;
              line-height: $m16px;
            }
            color: #4d4d4d;
            text-align: center;
          }
        }
      }
      .baseLink {
        margin-top: $d15px;
        display: grid;
        grid-template-columns: 1fr $d130px;
        align-items: center;
        @include mob {
          margin-top: $m15px;
          grid-template-columns: 1fr $m130px;
        }
        .textLink {
          font-family: "Arial";
          font-style: normal;
          font-weight: 400;
          font-size: $d12px;
          line-height: $d14px;
          color: #808080;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @include mob {
            font-size: $m10px;
            line-height: $m11px;
          }
        }
        .copyLink {
          text-align: right;
          font-size: $d12px;
          line-height: $d15px;
          @include mob {
            font-size: $m10px;
            line-height: $m13px;
          }
          a {
            font-family: "Druk Text Wide Cyr";
            font-style: normal;
            font-weight: 500;
            font-size: $d12px;
            line-height: $d15px;
            text-transform: uppercase;
            color: #4bb064;
            cursor: pointer;
            @include mob {
              font-size: $m12px;
              line-height: $d15px;
            }
          }
        }
      }
    }
  }
  .modalCard {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .shadow {
      display: block;
      background: rgba(190, 159, 201, 0.3);
    }
    .closeBox {
      position: relative;
      z-index: 101;
      min-height: auto;
      -moz-transform: scale(0.96, 0.96);
      -ms-transform: scale(0.96, 0.96);
      -webkit-transform: scale(0.96, 0.96);
      -o-transform: scale(0.96, 0.96);
      transform: scale(0.96, 0.96);
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-top: $d15px;
      margin-bottom: $d15px;
      width: $d375px;
      @include mob {
        padding-top: $m15px;
        width: $m375px;
        margin-bottom: $m15px;
      }
      a {
        display: block;
        width: $d24px;
        height: $d24px;
        background-image: url(../images/close-modal.svg);
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        @include mob {
          top: 0;
          width: $m24px;
          height: $m24px;
        }
      }
    }
    .card {
      position: relative;
      z-index: 101;
      min-height: auto;
      -moz-transform: scale(0.96, 0.96);
      -ms-transform: scale(0.96, 0.96);
      -webkit-transform: scale(0.96, 0.96);
      -o-transform: scale(0.96, 0.96);
      transform: scale(0.96, 0.96);
      width: $d375px;
      margin-right: auto;
      margin-left: auto;
      @include mob {
        width: 100%;
      }
    }
  }
}

.hEditLink {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  width: 10px;
  height: 10px;
}
.card {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mob {
    align-items: flex-start;
  }
  .container {
    width: $d375px;
    background: #ffffff;
    border-radius: $d20px;
    @include mob {
      border-radius: $m20px;
      width: 100%;
      max-width: 100%;
    }
    .imgContainer {
      width: $d375px;
      height: $d375px;
      border-radius: $d20px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      @include mob {
        width: $m375px;
        height: $m375px;
        border-radius: $m20px;
      }
    }
    .textContainer {
      padding: $d20px $d20px $d15px $d20px;
      @include mob {
        padding: $m20px $m20px $m15px $m20px;
      }
      .title {
        font-family: "Druk Text Wide Cyr";
        font-style: normal;
        font-weight: 500;
        font-size: $d20px;
        line-height: $d25px;
        color: #000000;
        margin-bottom: $d10px;
        @include mob {
          font-size: $m20px;
          line-height: $m25px;
          margin-bottom: $m10px;
        }
      }
      .position {
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: $d16px;
        line-height: $d18px;
        color: #000000;
        opacity: 0.8;
        @include mob {
          font-size: $m16px;
          line-height: $m18px;
        }
      }
      .org {
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: $d16px;
        line-height: $d18px;
        color: #000000;
        opacity: 0.8;
        margin-top: $d5px;
        @include mob {
          font-size: $m16px;
          line-height: $m18px;
          margin-top: $m5px;
        }
      }
      .site {
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: $d16px;
        line-height: $d18px;
        color: #000000;
        opacity: 0.8;
        margin-top: $d5px;
        @include mob {
          font-size: $m16px;
          line-height: $m18px;
          margin-top: $m5px;
        }
        a {
          font-size: $d16px;
          line-height: $d18px;
          color: #000000;
          text-decoration: underline;
          @include mob {
            font-size: $m16px;
            line-height: $m18px;
          }
        }
      }
      .filedBlocks {
        margin-top: $d20px;
        margin-bottom: $d20px;
        @include mob {
          margin-top: $m20px;
          margin-bottom: $m20px;
        }
        .filedBlock {
          display: grid;
          grid-template-columns: $d24px 1fr;
          gap: $d8px;
          @include mob {
            grid-template-columns: $m24px 1fr;
            gap: $m8px;
          }
          .icon {
            svg {
              width: $d24px;
              height: $d24px;
              opacity: 0.6;
              @include mob {
                width: $m24px;
                height: $m24px;
              }
            }
          }
          .title {
            opacity: 0.6;
          }
          .title,
          .title a {
            display: flex;
            align-items: center;
            font-family: "Arial";
            font-style: normal;
            font-weight: 400;
            font-size: $d16px;
            line-height: $d18px;
            color: #000000;
            height: 100%;
            text-decoration: none;
            @include mob {
              font-size: $m16px;
              line-height: $m18px;
            }
          }
          & + .filedBlock {
            margin-top: $d5px;
            @include mob {
              margin-top: $m5px;
            }
          }
        }
      }
      .raw {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $d13px;
        @include mob {
          gap: $m13px;
        }
      }
      .homeLinkBox {
        margin-top: $d15px;
        padding-top: $d11px;
        line-height: $d14px;
        text-align: center;
        border-top: $d1px solid rgba(0, 0, 0, 0.6);
        white-space: nowrap;
        @include mob {
          border-top: 1px solid rgba(0, 0, 0, 0.6);
          margin-top: $m15px;
          padding-top: $m11px;
          line-height: $m14px;
        }
        a {
          font-family: "Arial";
          font-style: normal;
          font-weight: 400;
          font-size: $d13px;
          line-height: $d14px;
          color: #4bb064;
          opacity: 1;
          letter-spacing: -0.01em;
          span {
            text-decoration: underline;
          }
          @include mob {
            font-size: $m13px;
            line-height: $m14px;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
      &.blackStyle {
      }
      &.lightStyle {
        border-top: $d1px solid rgba(252, 255, 253, 0.4);
        @include mob {
          border-top: 1px solid rgba(252, 255, 253, 0.4);
        }
        .title {
          color: #ffffff;
        }
        .position {
          color: #ffffff;
        }
        .org {
          color: #ffffff;
        }
        .site {
          color: #ffffff;
          a {
            color: #ffffff;
          }
        }
        .filedBlocks {
          .filedBlock {
            .title,
            .title a {
              color: #ffffff;
            }
          }
        }
        .homeLinkBox {
          a {
            color: #ffffff;
          }
        }
      }
    }
  }
}
.ps404 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    width: $d463px;
    margin: 0 autos;
    @include mob {
      width: $m355px;
    }
    .title {
      font-family: "Druk Text Wide Cyr";
      font-style: normal;
      font-weight: 900;
      font-size: $d150px;
      line-height: $d190px;
      color: #4bb064;
      margin-bottom: $d20px;
      @include mob {
        font-size: $m110px;
        line-height: $m139px;
        margin-bottom: $m20px;
      }
    }
    .error {
      font-family: "Druk Text Wide Cyr";
      font-style: normal;
      font-weight: 500;
      font-size: $d24px;
      line-height: 115%;
      color: #1a1a1a;
      margin-bottom: $d15px;
      @include mob {
        font-size: $m17px;
        margin-bottom: $m15px;
      }
    }
    .description {
      font-family: "Arial";
      font-style: normal;
      font-weight: 400;
      font-size: $d16px;
      line-height: $d18px;
      color: #1a1a1a;
      opacity: 0.8;
      @include mob {
        font-size: $m14px;
        line-height: $m16px;
      }
    }
  }
}

.loader {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 246, 224, 0.9);
  .box {
    width: 48px;
    height: 48px;
    position: relative;
    .i1,
    .i2,
    .i3 {
      position: absolute;
      width: 9px;
      height: 9px;
      border: 2px solid #be9fc9;
      border-radius: 12px;
      // @include transition(all 2.4s cubic-bezier(0.25, 0.1, 0.25, 1));
    }
    .i1 {
      top: 30px;
      left: 19.5px;
      animation: loaderFrameI1 3.25s infinite ease-in-out;
    }
    .i2 {
      opacity: 0.6;
      top: 12px;
      left: 31.5px;
      animation: loaderFrameI2 3.25s infinite ease-in-out;
    }
    .i3 {
      opacity: 0.3;
      top: 12px;
      left: 7.5px;
      animation: loaderFrameI3 3.25s infinite ease-in-out;
    }
  }
}

@keyframes loaderFrameI1 {
  0% {
    top: 30px;
    left: 19px;
  }
  3.83% {
    //back
    top: 32px;
    left: 17px;
  }
  21.83% {
    top: 11px;
    left: 32px;
  }
  24.33% {
    top: 12px;
    left: 31px;
  }
  33.33% {
    top: 12px;
    left: 31px;
  }
  37.16% {
    //back
    top: 12px;
    left: 33px;
  }
  55.16% {
    top: 12px;
    left: 6px;
  }
  57.66% {
    top: 12px;
    left: 7px;
  }
  66.66% {
    top: 12px;
    left: 7px;
  }
  70.49% {
    //back
    top: 10px;
    left: 5px;
  }
  88.49% {
    top: 31px;
    left: 20px;
  }
  90.99% {
    top: 30px;
    left: 19px;
  }
  100% {
    top: 30px;
    left: 19px;
  }
}

@keyframes loaderFrameI2 {
  0% {
    top: 12px;
    left: 31px;
  }
  3.83% {
    //back
    top: 12px;
    left: 33px;
  }
  21.83% {
    top: 12px;
    left: 6px;
  }
  24.33% {
    top: 12px;
    left: 7px;
  }
  33.33% {
    top: 12px;
    left: 7px;
  }
  37.16% {
    //back
    top: 10px;
    left: 5px;
  }
  55.16% {
    top: 31px;
    left: 20px;
  }
  57.66% {
    top: 30px;
    left: 19px;
  }
  66.66% {
    top: 30px;
    left: 19px;
  }
  70.49% {
    //back
    top: 32px;
    left: 17px;
  }
  88.49% {
    top: 11px;
    left: 32px;
  }
  90.99% {
    top: 12px;
    left: 31px;
  }
  100% {
    top: 12px;
    left: 31px;
  }
  // 0% {
  //   top:12px;
  //   left:31.5px;
  // }
  // 33.33% {
  //   top:12px;
  //   left:7.5px;
  // }
  // 66.66% {
  //   top:30px;
  //   left:19.5px;
  // }
  // 100% {
  //   top:12px;
  //   left:31.5px;
  // }
}

@keyframes loaderFrameI3 {
  0% {
    top: 12px;
    left: 7px;
  }
  3.83% {
    //back
    top: 10px;
    left: 5px;
  }
  21.83% {
    top: 31px;
    left: 20px;
  }
  24.33% {
    top: 30px;
    left: 19px;
  }
  33.33% {
    top: 30px;
    left: 19px;
  }
  37.16% {
    //back
    top: 32px;
    left: 17px;
  }
  55.16% {
    top: 11px;
    left: 32px;
  }
  57.66% {
    top: 12px;
    left: 31px;
  }
  66.66% {
    top: 12px;
    left: 31px;
  }
  70.49% {
    //back
    top: 12px;
    left: 33px;
  }
  88.49% {
    top: 12px;
    left: 6px;
  }
  90.99% {
    top: 12px;
    left: 7px;
  }
  100% {
    top: 12px;
    left: 7px;
  }
  // 0% {
  //   top:12px;
  //   left:7.5px;
  // }
  // 33.33% {
  //   top:30px;
  //   left:19.5px;
  // }
  // 66.66% {
  //   top:12px;
  //   left:31.5px;
  // }
  // 100% {
  //   top:12px;
  //   left:7.5px;
  // }
}
