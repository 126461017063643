.form {
  &-wrapper {
    padding: $d70px $d10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 100;
    display: none;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;
    @include mob {
      padding: $m14px $m10px;
    }
    &_opened {
      display: flex;
    }
    &_submited {
      align-items: center;
    }
    &::after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(190, 159, 201, 0.3);
    }
  }

  z-index: 110;
  border-radius: 25px;
  border: $d2px solid #be9fc9;
  background: #fff;
  min-width: $d575px;
  padding: $d28px $d30px $d30px;
  box-sizing: border-box;
  @include mob {
    min-width: none;
    width: 100%;
    padding: $m15px $m16px $m16px;
  }

  &-submited {
    z-index: 110;
    border-radius: 25px;
    border: $d2px solid #be9fc9;
    background: #fff;
    min-width: $d450px;
    padding: $d28px $d30px $d30px;
    box-sizing: border-box;
    @include mob {
      padding: $m18px $m15px $m16px $m16px;
      min-width: none;
      width: 100%;
    }
    .form__close {
      justify-content: space-between;
    }
  }

  &__success-text {
    color: #1a1a1a;
    font-family: Arial, sans-serif;
    font-size: $d20px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 27px */
    max-width: $d399px;
    padding-top: $d18px;
    @include mob {
      padding-top: $m13px;
      max-width: none;
      font-size: $m14px;
    }
  }

  &__to-main {
    padding-top: $d20px;
    @include mob {
      padding-top: $m24px;
      .btn {
        width: 100%;
        @include mob {
          text-transform: uppercase;
          font-size: $m10px;
        }
      }
    }
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    p {
      color: #1a1a1a;
      font-family: "Druk Text Wide Cyr", sans-serif;
      font-size: $d16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @include mob {
        font-size: $m16px;
      }
    }
  }

  &__submit {
    padding-top: $d34px;
    @include mob {
      padding-top: $m33px;
    }
    .btn {
      width: 100%;
      @include mob {
        text-transform: uppercase;
        font-size: $m10px;
      }
    }
  }

  &__inputs {
    padding-top: $d28px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: $d10px;
    column-gap: $d8px;
    max-width: $d515px;
    @include mob {
      padding-top: $m21px;
      row-gap: $m10px;
      max-width: none;
    }
  }

  &__input {
    grid-column: span 2;
    color: #1a1a1a;
    font-family: "Druk Text Wide Cyr", sans-serif;
    font-size: $d16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: $d15px;
    border-radius: 100px;
    border: $d2px solid #be9fc9;
    outline: none;
    @include mob {
      padding: $m15px $m15px $m17px $m15px;
      font-size: $m14px;
      border-width: $m2px;
    }
    &::placeholder {
      color: #1a1a1a;
    }
    &_half {
      grid-column: span 1;
      @include mob {
        grid-column: span 2;
      }
    }
    &_error {
      border-color: #f18666;
    }
  }

  &__textarea {
    box-sizing: border-box;
    min-height: $d116px;
    border-radius: 24px;
    resize: none;
    @include mob {
      min-height: $m116px;
    }
  }

  &__checkbox {
    position: relative;
    grid-column: span 2;
    padding-top: $d10px;
    display: flex;
    align-items: center;
    @include mob {
      padding-top: $m25px;
    }
    &-text {
      color: #808080;
      font-family: Arial, sans-serif;
      font-size: $d12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: $d16px;
      @include mob {
        font-size: $m10px;
        padding-left: $m16px;
        max-width: 70%;
      }
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
    input {
      display: none;
      &:checked + span {
        background-color: #be9fc9;
      }
    }
    span {
      width: $d24px;
      height: $d24px;
      border: $d2px solid #be9fc9;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mob {
        width: $m24px;
        height: $m24px;
        border-width: $m2px;
      }
    }
    label {
      display: flex;
      align-items: center;
      margin: 0;
      cursor: pointer;
    }
    &_error {
      span {
        border-color: #f18666;
      }
    }
  }
}
