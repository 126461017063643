@import "./vw_size.scss";
/**
 * Example of use: @include desk{}
*/
@mixin desk() {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin pad() {
  @media (min-width: 768px) and (max-width: 1199.98px) {
    @content;
  }
}
@mixin mob() {
  @media (max-width: 767.98px) {
    @content;
  }
}
@mixin touch() {
  @media screen and (pointer: coarse) {
    @content;
  }
}
@mixin transition($val) {
  -webkit-transition: $val;
  -moz-transition: $val;
  -o-transition: $val;
  transition: $val;
}
@mixin transform_rotate($val) {
  -webkit-transform: rotate($val);
  -moz-transform: rotate($val);
  -o-transform: rotate($val);
  transform: rotate($val);
}
