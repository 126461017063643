@import "@/assets/css/prepend.scss";
@import "./global.scss";
@mixin transform_rotate($val) {
  -webkit-transform: rotate($val);
  -moz-transform: rotate($val);
  -o-transform: rotate($val);
  transform: rotate($val);
}

@mixin transition($val) {
  -webkit-transition: $val;
  -moz-transition: $val;
  -o-transition: $val;
  transition: $val;
}
body,
html {
  min-height: 100vh;
}
html {
  background: #ffffff;
}
body {
  direction: ltr;
  background: #ffffff;
  color: #333333;
  overflow-x: hidden;
  font-family: "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
}
section {
  display: block;
}

button {
  background: none;
  border: none;
}

ul {
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li,
p,
h1,
h2 {
  padding: 0;
  margin: 0;
}

.selectorInput {
  position: relative;
  width: 100%;
  margin-bottom: 25px;
  input[type="text"] {
    margin-bottom: 0px;
  }
  .toggleSelectorIndicator {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 12px;
    &::before {
      display: block;
      content: "";
      // background-image: url('../images/v-down.svg');
      background-repeat: no-repeat;
      background-position: center center;
      @include transition(all 0.3s ease-in-out);
      width: 12px;
      height: 100%;
    }
  }
  input[type="text"]:focus + .toggleSelectorIndicator {
    &::before {
      @include transform_rotate(180deg);
    }
  }
  .clearSelector {
    position: absolute;
    right: -1px;
    top: 0;
    height: 100%;
    width: 22px;
    display: none;
    &::before {
      display: block;
      content: "";
      // background-image: url('../images/clear.svg');
      background-repeat: no-repeat;
      background-position: center center;
      width: 22px;
      height: 100%;
    }
  }

  input[type="text"]:focus
    + .toggleSelectorIndicator
    + .selector
    + .clearSelector {
    display: block;
  }
  .selector {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    background: #ffffff;
    max-height: 400px;
    overflow: auto;
    display: none;
    z-index: 101;
    &::-webkit-scrollbar {
      height: 15px;
      width: 15px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-button {
      height: 3px;
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      background: #e5e5e5;
      border-radius: 6px;
      border: none;
      border-right: 4px solid #ffffff;
      border-left: 4px solid #ffffff;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    a {
      display: block;
      height: 40px;
      font-size: 16px;
      line-height: 40px;
      color: #020202;
      text-decoration: none;
      outline: 0;
      text-align: left;
      padding-left: 17px;
      &.other {
        background: #efefef;
        cursor: pointer;
        &:hover {
          background: #efefef;
          color: #020202;
        }
      }
      &:hover {
        background: #020202;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }

  input[type="text"]:focus + .toggleSelectorIndicator + .selector {
    display: block;
  }
}
.checkbox {
  display: inline-block;
  width: 22px;
  height: 22px;
  user-select: none;
  margin-right: 16px;
  vertical-align: middle;
  //background-image: url('../images/checkbox.svg');
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 2px;
  &.checked {
    //background-image: url('../images/checkbox-checked.svg');
  }
}

.btn {
  font-family: "Druk Text Wide Cyr", sans-serif;
  color: #020202;
  text-align: center;
  box-sizing: border-box;
  min-width: $d450px;
  padding: $d20px $d30px;
  font-size: $d16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 100px;
  text-decoration: none;
  display: inline-block;

  @include mob {
    padding: $m20px $m30px;
    font-size: $m16px;
  }

  &:hover {
    background: none !important;
    text-decoration: none;
    color: #020202;
  }

  &_green {
    background-color: #4bb064;
    border: $d2px solid #4bb064;
    @include mob {
      border: 2px solid #4bb064;
    }
    &:hover {
      @include touch {
        background-color: #4bb064;
      }
    }
  }

  &_violet {
    background-color: #be9fc9;
    border: $d2px solid #be9fc9;
    @include mob {
      border: 2px solid #be9fc9;
    }
    &:hover {
      @include touch {
        background-color: #be9fc9;
      }
    }
  }
}
